import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close() {
    const toast = this.element;

    toast.classList.add('opacity-0', 'transition-opacity', 'duration-300');
    
    setTimeout(() => {
      toast.classList.add('hidden');
    }, 300);
  }
}