import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["results"]

  async filter(event) {
    event.preventDefault()  // Prevent the form from submitting normally

    const url = new URL(this.element.action)  // Get form action URL
    const formData = new FormData(this.element)

    // Append form data to the URL as query parameters
    formData.forEach((value, key) => {
      url.searchParams.append(key, value)
    })

    // Handle the specific 'item_type' filter
    const itemType = formData.get("item_type")
    if (itemType) {
      url.searchParams.set("item_type", itemType)  // Ensure 'item_type' is set correctly in the URL
    }

    const response = await fetch(url, {
      method: "GET",
      headers: { "Accept": "text/vnd.turbo-stream.html" }
    })

    if (response.ok) {
      const html = await response.text()
      this.resultsTarget.innerHTML = html  // Update the results
    }
  }
}