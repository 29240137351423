import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { endTime: Number }

  connect() {
    this.startTimer();
  }

  startTimer() {
    const endTime = this.endTimeValue;

    this.timer = setInterval(() => {
        const now = Date.now();
        const remaining = endTime - now;

        if (remaining <= 0) {
            clearInterval(this.timer);
            this.element.textContent = "Subasta Finalizada";
            this.finishAuction();
        } else {
            const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
            const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
            const totalHours = Math.floor(remaining / (1000 * 60 * 60));

            this.element.textContent = `${String(totalHours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
        }
    }, 1000);
  }

  finishAuction() {
    const auction_id = this.element.dataset.auctionId;

    const csrfToken = document.querySelector("meta[name='csrf-token']").content;
    if (!csrfToken) {
      console.error("CSRF token is missing.");
      return;
    }

    fetch(`/admin/auctions/${auction_id}/finalize`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify({ finished: true })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log("Auction finished successfully:", data);
    })
    .catch(error => {
      console.error("Error finishing the auction:", error);
    });
  }

  // Update the timer if the endTime changes
  set endTimeValue(value) {
    this.endTime = value;
    this.startTimer();
  }
}
